import { round, uniq } from "lodash";
import { InstrumentDTO } from "../instruments/instrumentsApiSlice";
import { RfqResponseDTO } from "../notifications/rfqApiSlice";
import { OrderDTO } from "../orders/order/ordersApiSlice";
import { getDecimalScale } from "../summary/components/LimitPriceInput";
import mockedLivePrice from "./mockedLivePrice";

export interface Price {
  value: number;
  isElectronic: boolean;
}

interface PriceInfo {
  prices: Price[];
  floor: number;
  ceiling: number;
}

/**
 * @returns An array of prices based on market offers, underlying tick size,
 * and any working orders.
 */
export const getPrices = (
  orders: OrderDTO[] | undefined,
  rfqResponses: RfqResponseDTO[] | undefined,
  selectedPrice: number | null,
  instrument: InstrumentDTO | null
): PriceInfo => {
  if (!instrument?.tick_size) {
    return { prices: [], floor: 0, ceiling: 0 };
  }
  const decimalScale = getDecimalScale(instrument.tick_size);
  // If we get a bad response from the API, return an empty object.
  if (orders === undefined || !instrument) return { prices: [], floor: 0, ceiling: 0 };

  const orderPrices = orders.map((order) => order.limit_price);
  const intickPrices: number[] = [];

  const { ceiling, floor } = mockedLivePrice(instrument);

  const end = round(floor - instrument.tick_size, decimalScale);

  // A list of all the prices that should be included (but might not be exactly
  // an integer multiple of the InTick tick size).
  const importantPrices = uniq([...orderPrices]);

  if (selectedPrice && !importantPrices.includes(selectedPrice)) {
    importantPrices.push(selectedPrice);
  }

  // Iterate from max -> min.
  for (let i = ceiling; round(i, decimalScale) > end; i -= instrument.tick_size) {
    // InTick intervals are added here (there should be 9 in total).
    // Need to account for JS float precision errors, hence lots of rounding.
    intickPrices.push(round(i, decimalScale));
  }

  // Combine the InTick prices and the important prices, with no duplicates.
  const prices: Price[] = uniq([...importantPrices, ...intickPrices])
    .map((p) => {
      return {
        value: p,
        isElectronic: p === ceiling || p === floor,
      };
    })
    .sort((a, b) => b.value - a.value); // Return prices in descending order.

  return { prices, floor, ceiling };
};
