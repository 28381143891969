import { useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import { selectInstrument } from "../instruments/instrumentSlice";
import { RfqResponseDTO, RfqResponseState, useGetRfqResponsesQuery } from "../notifications/rfqApiSlice";
import { OrderDTO, OrderState, useGetOrdersByInstrumentQuery } from "../orders/order/ordersApiSlice";
import { Direction } from "../orders/parent/parentApiSlice";

export default function usePriceQueries() {
  const selectedInstrument = useAppSelector(selectInstrument);

  const {
    currentData: ordersData,
    error: ordersError,
    isError: isErrorOrders,
    isSuccess: isSuccessOrders,
    refetch: refetchOrders,
    isLoading: isLoadingOrders,
  } = useGetOrdersByInstrumentQuery(selectedInstrument?.instrument_id || "", {
    pollingInterval: refetchTimeouts.fast,
    skip: !selectedInstrument,
  });

  const { currentData: rfqResponsesData } = useGetRfqResponsesQuery(undefined, {
    pollingInterval: refetchTimeouts.fast,
  });

  /**
   * Returns either the best Bid or the best Ask (if there is one).
   */
  const getBestPrice = (direction: Direction, orders: (OrderDTO | RfqResponseDTO)[]): number | undefined => {
    const workingOrders = orders.filter(
      (order) =>
        order.direction === direction &&
        (order.state === OrderState.Working || order.state === RfqResponseState.Pending)
    );
    // We can type children as offers because they also offers.
    if (!workingOrders.length) return undefined;
    let bestPrice = workingOrders[0]?.limit_price || 0;
    workingOrders.forEach((order) => {
      if (direction === Direction.SELL) {
        bestPrice = bestPrice < order.limit_price ? bestPrice : order.limit_price;
      } else {
        bestPrice = bestPrice > order.limit_price ? bestPrice : order.limit_price;
      }
    });
    return bestPrice;
  };
  return {
    ordersData,
    ordersError,
    isErrorOrders,
    isSuccessOrders,
    refetchOrders,
    getBestPrice,
    isLoadingOrders,
    rfqResponsesData,
  };
}
