import { BrokerDTO, useGetUserCompanyQuery } from "@/features/auth/authApiSlice";
import { selectAuthUser } from "@/features/auth/authSlice";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "./AppBar";

export default function AccountInfo() {
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);

  const { currentData: company } = useGetUserCompanyQuery(user?.company_id || "", {
    skip: !user?.company_id,
  });
  if (!user || !company) return null;

  return (
    <>
      <AppBar dashboard={false} />
      <Grid component="main" container spacing={2} sx={{ mt: 14 }}>
        <Grid item xs={4}>
          <Stack spacing={2} px={4}>
            <TextField
              id="account-info-username"
              label="Username"
              defaultValue={user.username}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-full-name"
              label="Full Name"
              defaultValue={user.last_name ? user.first_name + " " + user.last_name : user.first_name}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-user-id"
              label="User ID"
              defaultValue={user.user_id}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-email-address"
              label="Email Address"
              defaultValue={user.email}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-company-full-name"
              label="Company Name"
              defaultValue={company.name}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-company-full-name"
              label="Company Full Name"
              defaultValue={company.full_name}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-company-id"
              label="Company ID"
              defaultValue={company.company_id}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="account-info-default-broker"
              label="Default Broker"
              defaultValue={company.default_broker.name}
              InputProps={{
                readOnly: true,
              }}
            />

            {company.brokers.length ? (
              company.brokers.map((broker: BrokerDTO, i: number) => (
                <TextField
                  key={i}
                  id={`account-info-broker-${broker.name}`}
                  label={`Connected Broker: ${i + 1}`}
                  defaultValue={broker.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ))
            ) : (
              <Typography variant="caption" pl={2}>
                No other connected brokers.
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack>
            <Button sx={{ width: 196 }} onClick={() => navigate("/dashboard")}>
              Return to Dashboard
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
