import theme from "@/app/theme";
import { baseApi } from "@/config/BaseQuery";
import { handleLogout, selectAuthUser } from "@/features/auth/authSlice";
import { useMenu } from "@/hooks/useMenu";
import { resetState } from "@/store/reset";
import { stringToColour } from "@/utils/Colour";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@mui/system/styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const avatarSize = 32;
const padding = 4;

const AccountButton = styled(Button)(() => ({
  "padding": `${padding}px ${2 * padding}px`,
  "borderRadius": avatarSize + 2 * padding,
  "maxHeight": avatarSize + 2 * padding,
  "textTransform": "none",
  "color": theme.palette.primary.main,
  "backgroundColor": theme.palette.grey.A200,
  "boxShadow": "none",
  "&:hover": { boxShadow: "none" },
  "marginLeft": `${padding * 2}px`,
}));

export default function ProfileMenu() {
  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);
  if (!user) return null;

  const getInitials = () => {
    if (!user.first_name) return "";
    const names: [string, string] = [user.first_name, user.last_name || ""];
    let initials = names[0].substring(0, 1);
    if (names.length > 1) {
      initials += (names[names.length - 1] as string).substring(0, 1);
    }
    return initials.toUpperCase();
  };

  const handleNavigation = (route: string) => {
    navigate(route);
    handleMenuClose();
  };

  const menuId = "profile-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleNavigation("/user/account")}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>My Account</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(handleLogout());
          dispatch(resetState);
          dispatch(baseApi.util.resetApiState());
        }}
      >
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText data-testid="logout">Logout</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AccountButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
        variant="contained"
        startIcon={
          <Avatar
            sx={{
              height: avatarSize,
              width: avatarSize,
              fontSize: 12,
              backgroundColor: stringToColour(user.username),
            }}
          >
            {getInitials()}
          </Avatar>
        }
        endIcon={isMenuOpen ? <ArrowDropUpIcon sx={{ ml: -1 }} /> : <ArrowDropDownIcon sx={{ ml: -1 }} />}
        data-testid="profile-button"
      />
      {renderMenu}
    </>
  );
}
