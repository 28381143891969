import { themeColors } from "@/app/theme";
import { selectInstrument } from "@/features/instruments/instrumentSlice";
import { RfqResponseDTO } from "@/features/notifications/rfqApiSlice";
import { addItem, removeItem } from "@/features/orderbook/pricesSlice";
import { OrderDTO } from "@/features/orders/order/ordersApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

// Prevent clicking on dialog box from invoking onClick property of its parent component.
const handleDialogClick = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export interface EditAmountDialogProps {
  order: OrderDTO | RfqResponseDTO;
  orderQuantity: number;
  setOrderQuantity: React.Dispatch<React.SetStateAction<number>>;
  open: boolean;
  isOrderSelected: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason: "backdropClick" | "escapeKeyDown" | "cancelButtonClick" | "doneButtonClick"
  ) => void;
}

// Using a space stops the dialog from changing height when the helper text
// pops in and out. Constant is used to check for error status.
const NO_ERROR = " ";

export default function EditAmountDialog(props: EditAmountDialogProps) {
  const dispatch = useAppDispatch();
  const instrument = useAppSelector(selectInstrument);

  const handleCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClose(event, "cancelButtonClick");
  };

  // State variables used to store TextField input
  const [textFieldValue, setTextFieldValue] = useState(props.orderQuantity);

  useEffect(() => {
    // Parent will update this prop if we need to reset the quantity
    // back to full.
    setTextFieldValue(props.orderQuantity);
  }, [props.orderQuantity]);

  // TextField helperText depends on if / how the value is invalid
  const [helperText, setHelperText] = useState(NO_ERROR);

  useEffect(() => {
    if (instrument === null) {
      setHelperText("Waiting for instrument response.");
    } else if (textFieldValue < instrument.min_quantity) {
      setHelperText("Value is below minimum block size.");
    } else if (textFieldValue > props.order.quantity) {
      setHelperText("Value is above original quantity.");
    } else if (textFieldValue <= 0) {
      setHelperText("Invalid value.");
    } else if (props.order.quantity - textFieldValue < instrument.min_quantity) {
      setHelperText(
        (props.order as OrderDTO).residual ? NO_ERROR : "Residual quantity is below the minimum block size."
      );
    } else {
      setHelperText(NO_ERROR);
    }
  }, [textFieldValue, instrument]);

  // If the offer is selected, remove and re-add the offer to state so that the summary box
  // reflects an up to date quantity sum.
  useEffect(() => {
    if (props.isOrderSelected) {
      console.log(props.order);
      dispatch(removeItem(props.order));
      dispatch(addItem({ ...props.order, quantity: props.orderQuantity }));
    }
  }, [props.orderQuantity]);

  const handleDoneButton = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
    props.setOrderQuantity(textFieldValue);

    props.onClose(event, "doneButtonClick");
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && helperText === NO_ERROR) {
      handleDoneButton(event);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onClick={handleDialogClick}
      PaperProps={{ sx: { minHeight: "240px" } }}
      onKeyUp={handleEnterPress}
    >
      <DialogTitle id={"dialog-title"}>
        <Box display={"flex"} justifyContent={"space-between"} minWidth={400}>
          <Stack>
            Edit amount
            <Typography sx={{ fontSize: 12 }}>You're about to update the amount</Typography>
          </Stack>
          <IconButton
            onClick={handleCloseButton}
            sx={{
              height: 24,
              width: 24,
              color: themeColors.text.primary,
            }}
          >
            <ClearIcon
              sx={{
                height: 16,
                width: 16,
                color: themeColors.text.primary,
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <NumericFormat
          value={textFieldValue}
          displayType={"input"}
          thousandSeparator
          customInput={TextField}
          label={"Quantity"}
          size={"medium"}
          onValueChange={(values) => {
            setTextFieldValue(values?.floatValue ?? 0);
          }}
          helperText={helperText}
          fullWidth
          sx={{
            "mt": 1,
            "minWidth": "230px",
            ".MuiFormHelperText-root": { whiteSpace: "nowrap" },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", pb: 2, pt: 0, px: 3 }}>
        <Button color={"rfqDark"} variant={"contained"} onClick={handleDoneButton} disabled={helperText !== NO_ERROR}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
