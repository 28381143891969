import { InstrumentDTO } from "../instruments/instrumentsApiSlice";

interface LivePrice {
  ceiling: number;
  floor: number;
}

const mockedLivePrice = (instrument: InstrumentDTO | null): LivePrice => {
  if (!instrument) {
    return {
      ceiling: 0,
      floor: 0,
    };
  }
  const name = instrument.name;
  if (name === "Euro Stoxx 50") {
    return {
      ceiling: 4981.7,
      floor: 4981.45,
    };
  }

  if (name === "Euro Stoxx Banks") {
    return {
      ceiling: 146.35,
      floor: 146.33,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (name === "Bobl") {
    return {
      ceiling: 106.61,
      floor: 106.6,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (name === "Stoxx Europe 600") {
    return {
      ceiling: 518.29,
      floor: 518.27,
    };
  }

  if (name === "Schatz") {
    return {
      ceiling: 110.59,
      floor: 110.585,
    };
  }

  if (name === "SMI") {
    return {
      ceiling: 11719,
      floor: 11718,
    };
  }

  if (name === "Long Gilt") {
    return {
      ceiling: 689.5,
      floor: 689.4,
    };
  }

  if (name === "Bund") {
    return {
      ceiling: 133.7,
      floor: 133.69,
    };
  }

  if (name === "Bobl") {
    return {
      ceiling: 128.09,
      floor: 128.08,
    };
  }

  if (name === "BTP") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (name === "OAT") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (name === "Short BTP") {
    return {
      ceiling: 107.46,
      floor: 107.45,
    };
  }

  if (name === "Buxl") {
    return {
      ceiling: 135.41,
      floor: 135.39,
    };
  }
  return {
    ceiling: 0,
    floor: 0,
  };
};

export default mockedLivePrice;
