import { baseApi } from "@/config/BaseQuery";

export interface PostMatchRequest {
  requesting_parent_order_id: string;
  responding_order_id: string;
  quantity: number;
}

export interface MatchRequestDTO {
  order_match_request_id: string;
  created_by_user_id: string;
  quantity: number;
  residual_quantity: number;
  state: OrderMatchRequestState;
  requesting_broker_id: string;
  requesting_parent_order_id: string;
  requesting_order_id: string;
  responding_order_id: string;
  created_by_company_name: string;
  instrument_id: string;
  expires_in_seconds: number;
  expired_at: string;
  created_at: string;
  updated_at: string;
}

export enum OrderMatchRequestState {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Pending = "PENDING",
  Cancelled = "CANCELLED",
  TimedOut = "TIMED_OUT",
  PartialBrokerAccepted = "PARTIAL_BROKER_ACCEPTED",
  BrokedAccepted = "BROKER_ACCEPTED",
  PartialBrokerFilled = "PARTIAL_BROKER_FILLED",
  BrokerFilled = "BROKER_FILLED",
}

export interface PatchOrderMatchRequest {
  id: string;
  state: OrderMatchRequestState;
}

const matchApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    acceptMatch: build.mutation<MatchRequestDTO, string>({
      query: (id) => ({
        url: `/order-match-requests/${id}`,
        method: "PATCH",
        body: { state: OrderMatchRequestState.Accepted },
      }),
      invalidatesTags: [
        "Orders",
        "MyOrders",
        "SelectedOrder",
        "MatchRequestNotifications",
        "SelectedParentOrder",
        "RfqResponses",
      ],
    }),
    rejectMatch: build.mutation<MatchRequestDTO, string>({
      query: (id) => ({
        url: `/order-match-requests/${id}`,
        method: "PATCH",
        body: { state: OrderMatchRequestState.Rejected },
      }),
      invalidatesTags: ["Orders", "SelectedOrder", "MatchRequestNotifications", "SelectedParentOrder", "RfqResponses"],
    }),
    cancelMatch: build.mutation<MatchRequestDTO, string>({
      query: (id) => ({
        url: `/order-match-requests/${id}`,
        method: "PATCH",
        body: { state: OrderMatchRequestState.Cancelled },
      }),
      invalidatesTags: ["SelectedOrder", "Orders", "MyOrders", "SelectedParentOrder", "RfqResponses"],
    }),
    requestMatch: build.mutation<MatchRequestDTO, PostMatchRequest>({
      query: (body) => ({
        url: "/order-match-requests",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SelectedOrder", "Orders", "MyOrders", "SelectedParentOrder"],
    }),
  }),
});

export const { useAcceptMatchMutation, useRejectMatchMutation, useCancelMatchMutation, useRequestMatchMutation } =
  matchApiSlice;
