import { CH, DE, EU, FR, GB, IT } from "country-flag-icons/react/3x2";

export interface FlagIconProps {
  instrumentName: string;
  height: number;
}

export default function FlagIcon(props: FlagIconProps) {
  if (!props.instrumentName) return null;
  switch (props.instrumentName) {
    case "Euro Stoxx 50":
    case "Euro Stoxx Banks":
    case "Stoxx Europe 600":
      return <EU height={props.height} />;
    case "Bund":
    case "Schatz":
    case "Buxl":
    case "Bobl":
      return <DE height={props.height} />;
    case "OAT":
      return <FR height={props.height} />;
    case "BTP":
    case "Short BTP":
      return <IT height={props.height} />;
    case "SMI":
      return <CH height={props.height} />;
    case "Long Gilt":
      return <GB height={props.height} />;
    default:
      return null;
  }
}
