import ErrorCard from "@/components/ErrorCard";
import ScrollBox from "@/components/ScrollBox";
import PriceCard from "@/features/orderbook/components/PriceCard";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { range } from "lodash";
import { useEffect, useState } from "react";
import { selectInstrument } from "../instruments/instrumentSlice";
import { RfqResponseDTO } from "../notifications/rfqApiSlice";
import { OrderDTO } from "../orders/order/ordersApiSlice";
import { Direction } from "../orders/parent/parentApiSlice";
import { selectParent } from "../orders/parent/parentSlice";
import { getPrices, Price } from "./getPrices";
import mockedLivePrice from "./mockedLivePrice";
import {
  selectElectronicPrices,
  selectPrice,
  setPrice,
  setElectronicPrices as setStoreElectronicPrices,
  setPrices as setStorePrices,
} from "./pricesSlice";
import usePriceQueries from "./usePriceQueries";

const OrderBook = () => {
  const selectedParent = useAppSelector(selectParent);
  const selectedPrice = useAppSelector(selectPrice);
  const selectedInstrument = useAppSelector(selectInstrument);
  const dispatch = useAppDispatch();

  const [activePrice, setActivePrice] = useState<number | null>(null);

  const { ordersData, rfqResponsesData, ordersError, isErrorOrders, refetchOrders, getBestPrice, isLoadingOrders } =
    usePriceQueries();
  // Generate prices based on either the offers + instrument stats,
  // working orders, or a manually set limit price.
  const [prices, setPrices] = useState<Price[]>([]);
  const [orderBookData, setOrderBookData] = useState<(OrderDTO | RfqResponseDTO)[]>([]);
  const selectedElectronicPrices = useAppSelector(selectElectronicPrices);
  const [electronicPrices, setElectronicPrices] = useState(selectedElectronicPrices);
  useEffect(() => {
    // Timeout is used to debounce the function. User can freely enter a limit
    // price via the SummaryBox, and we need to ensure that the function does
    // not execute between keystrokes.
    const generatePrices = setTimeout(() => {
      const priceInfo = getPrices(ordersData, rfqResponsesData, selectedPrice, selectedInstrument);
      setPrices(priceInfo.prices);
      dispatch(setStorePrices(priceInfo.prices));
      setElectronicPrices(mockedLivePrice(selectedInstrument));

      if (selectedPrice !== activePrice) {
        // Ensures the price cards know the current active price.
        setActivePrice(selectedPrice);
      }
    }, 100);

    return () => clearTimeout(generatePrices);
  }, [ordersData, selectedPrice, rfqResponsesData]);

  useEffect(() => {
    dispatch(setStoreElectronicPrices(electronicPrices));
  }, [electronicPrices]);

  useEffect(() => {
    const a = ordersData?.length ? [...ordersData] : [];
    const b = rfqResponsesData?.length ? [...rfqResponsesData] : [];
    setOrderBookData([...a, ...b]);
  }, [rfqResponsesData, ordersData]);
  const onClickPrice = (value: number) => {
    const newPrice = activePrice === value ? null : value;
    dispatch(setPrice(newPrice)); // Inform the SummaryBox.
    setActivePrice(newPrice); // Inform PriceCard & OfferCard components.
  };

  if (isErrorOrders) {
    return <ErrorCard error={ordersError} refreshFn={refetchOrders} />;
  }

  if (isLoadingOrders) {
    return (
      <Stack spacing={2}>
        {range(5).map((i) => (
          <Skeleton key={i} height={120} variant="rounded" width="100%">
            <PriceCard
              price={{ value: 0, isElectronic: false }}
              orders={[]}
              isBest={false}
              onClick={onClickPrice}
              selectedPrice={activePrice}
            />
          </Skeleton>
        ))}
      </Stack>
    );
  }

  // Find the best bid and ask (if they exist).
  const bestAsk = getBestPrice(Direction.SELL, orderBookData || []);
  const bestBid = getBestPrice(Direction.BUY, orderBookData || []);
  const hasLiquidity = !!bestBid || !!bestAsk;
  return (
    <ScrollBox offset={!!selectedParent ? 4 : 12}>
      {prices.map((price, i) => {
        // Find all the offers at this price.
        const sellsAtPrice =
          orderBookData?.filter((order) => order.limit_price === price.value && order.direction === Direction.SELL) ||
          [];

        const buysAtPrice =
          orderBookData?.filter((order) => order.limit_price === price.value && order.direction === Direction.BUY) ||
          [];

        const rfqResponsesWithoutDirection =
          orderBookData?.filter((order) => order.limit_price === price.value && !order.direction) || [];
        // I.e. this price contains either the best ask or best bid.
        const isBestAsk = sellsAtPrice.some((order) => order.limit_price === bestAsk);
        const isBestBid = buysAtPrice.some((order) => order.limit_price === bestBid);
        return (
          <div key={i}>
            {!!sellsAtPrice.length && (
              <PriceCard
                price={price}
                isFirst={i === 0}
                isLast={i === prices.length - 1}
                isBest={isBestAsk}
                scrollScreenToThis={hasLiquidity}
                orders={sellsAtPrice}
                onClick={onClickPrice}
                selectedPrice={activePrice}
              />
            )}
            {!!buysAtPrice.length && (
              <PriceCard
                data-testid={`price-card-${price}`}
                price={price}
                isFirst={i === 0}
                isLast={i === prices.length - 1}
                scrollScreenToThis={hasLiquidity}
                isBest={isBestBid}
                orders={buysAtPrice}
                onClick={onClickPrice}
                selectedPrice={activePrice}
              />
            )}
            {!!rfqResponsesWithoutDirection.length && (
              <PriceCard
                data-testid={`price-card-${price}`}
                price={price}
                isFirst={i === 0}
                isLast={i === prices.length - 1}
                scrollScreenToThis={hasLiquidity}
                isBest={isBestBid}
                orders={rfqResponsesWithoutDirection}
                onClick={onClickPrice}
                selectedPrice={activePrice}
              />
            )}
            {buysAtPrice.length === 0 && sellsAtPrice.length === 0 && (
              <PriceCard
                price={price}
                isFirst={i === 0}
                scrollScreenToThis={!hasLiquidity && i === 2}
                isLast={i === prices.length - 1}
                isBest={false}
                orders={[]}
                onClick={onClickPrice}
                selectedPrice={activePrice}
              />
            )}
          </div>
        );
      })}
    </ScrollBox>
  );
};

export default OrderBook;
